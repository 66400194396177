import { useState, useEffect } from "react";

function Resume() {
  useEffect(() => {
    document.title = "my resume ᝰ.ᐟ";
  }, []);

  return (
    <div>
      <p>here's my resume 𓍢ִ໋🌷͙֒</p>
      <br/>

      <iframe
        src="https://drive.google.com/file/d/1ACQ3JGrBgTNtmfUHvKAVkLD_iM_WcbOq/preview"
        width="640"
        height="825"
        allow="autoplay"
      ></iframe>
    </div>
  );
}

export default Resume;