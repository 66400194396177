import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const hobbies = [
  "crocheting ‧₊˚ 🎐 ⋅",
  "learning silly dances on tiktok 👻",
  "reading romance mangas 🫠",
  "simping over kpop girl groups 🎸⋆⭒˚｡⋆",
  "playing nonogram 👀",
  "making spotify playlists (most of them will probably have laufey lol) 🫧🎧",
  "ordering boba ♡‧₊˚",
  "looking up new kdramas",
  "arguing with my friends whether hotpot 757 or korean garden is better ꉂ`🍥`",
  "redecorating my notion for the 378th time that month 🩰",
  "drinking oatmilk matcha latte ₊˚ 🍵",
  "on the phone yapping omw to class 🍳",
  "taking pictures with my five different film cameras °📷 ༘ ⋆｡˚",
  "listening to aespa and seventeen (go kpop!)",
];

function useWordCycle(words) {
  const [currentWord, setCurrentWord] = useState(
    words[Math.floor(Math.random() * words.length)]
  );

  const handleClick = () => {
    const currentIndex = words.indexOf(currentWord);
    const nextIndex = (currentIndex + 1) % words.length;
    setCurrentWord(words[nextIndex]);
  };

  return [currentWord, handleClick];
}

function Home() {
  useEffect(() => {
    document.title = "bobobapal ・ᡣ𐭩";
  }, []);

  const [currentHobby, handleHobbyClick] = useWordCycle(hobbies);

  return (
    <div>
      <h2>hello there, i'm leah </h2>
      <p>
        welcome to my little page 🤍

      </p>
      <p>
        after 18 years living in hanoi, vietnam, i am now a college sophomore at the university of richmond. when i'm not trying to be an academic weapon and making ramen in my dorm, you can catch me{" "}
        <a class="clickable" id="hobbies" onClick={handleHobbyClick}>
          {currentHobby}
        </a>
        .
      </p>
      <p>
        reach out to me anytime at{" "}
        <a href="mailto:leah.phw@gmail.com" class="clickable">
          leah.phw@gmail.com
        </a>
      </p>
    </div>
  );
}

export default Home;