import { useState, useEffect } from "react";

const useTheme = () => {
  const [theme, setTheme] = useState("day");

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      setTheme(currentTheme);
    } else {
      const prefersDarkMode = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (prefersDarkMode) {
        setTheme("night");
      }
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "night" ? "day" : "night";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.documentElement.classList.toggle("day");
    document.documentElement.classList.toggle("night");
  };

  return [theme, toggleTheme];
};

export default useTheme;