import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const majors = [
  "computer science 🪩",
  "mathematics 📑",
  "economics ♣︎",
  "finance (bro) ᕙ(⇀‸↼‶)ᕗ",
  "pre-engineering (?)",
  "visual arts and media (●´⌓`●)",
];

function useWordCycle(words) {
  const [currentWord, setCurrentWord] = useState(
    words[Math.floor(Math.random() * words.length)]
  );

  const handleClick = () => {
    const currentIndex = words.indexOf(currentWord);
    const nextIndex = (currentIndex + 1) % words.length;
    setCurrentWord(words[nextIndex]);
  };

  return [currentWord, handleClick];
}

function About() {
  useEffect(() => {
    document.title = "leah learns ₊˚🎧⊹♡";
  }, []);

  const [mymajors, handleMajorClick] = useWordCycle(majors);


  return (
    <div>
      <img src="/images/me.png" alt="my picture :>" width="150" height="auto" style={{ borderRadius: '50%' }}/>
      <br/>
      <br/>
      <p>
        here are some smol facts about me ( ๑‾̀◡‾́)σ" i like travelling, reading, and learning new (random) things. 
        <br />
        <br />
        i am a bit here and there, so you might see me learning a new buldak recipe while trying to figure out operating systems at the same time ;-; 
        nonetheless, i welcome any learning opportunity out there and i will definitely try my best ʚ✩ɞ.
        <br />
        <br />
        while i'm still trying to figure out my passions, i like to try new things and push
        myself out of my comfort zone. (❛‿❛) i like to learn from the people around me so please connect 🤍
      </p>
      <br/>
      <br/>
      <p>
        <h2>currently</h2>
      </p>
      <p>
        i'm a sophomore at{" "}
        <a href="https://www.richmond.edu/" target="_blank" class="clickable">
          university of richmond
        </a>
        , working on my{" "}
        <a class="clickable" id="majors" onClick={handleMajorClick}>
          {mymajors}
        </a> degree and <a href="https://blog.richmond.edu/hive-lab/" target="_blank" class="clickable">
          autonomous dancing robots research!
        </a>
      </p>
      <br />
      <br/>
      <p>
        <h2>i also dabbled in</h2>
        <p>some internships 💻 </p>
      </p>
      <ul>
        <li>improving search quality @ google</li>
        <li>
          developed a subgraph for direct data query from the ethereum blockchain for web3 games (
          <a
            href="https://github.com/leahphw/pgx-liquidity-subgraph"
            class="clickable"
            target="_blank"
          >
            github
          </a>
          )
        </li>
        <li>
          created websites for non-profits (
          <a
            href="https://www.developforgood.org/?gad_source=1&gclid=CjwKCAjw17qvBhBrEiwA1rU9w1hYghMW1CtS04zqAlNYKxDJpogjQdA3uJmktljnX9y-lx26vqhkKhoC5JwQAvD_BwE"
            class="clickable"
            target="_blank"
          >
            Develop for Good
          </a>
          )
        </li>
        <li>
          ta for data structures and algorithms
        </li>
        <li>
          wrote test suites for some web3 games (
          <a
            href="https://mirailabs.co/"
            class="clickable"
            target="_blank"
          >
            Mirai Labs
          </a>
          )
        </li>
        <li>
          wrote some website code and organize travel events for{" "}
          <a
            href="http://urgwc.com"
            target="_blank"
            class="clickable"
          >
            Girls Who Code
          </a>
        </li>  
      </ul>
    </div>
  );
}

export default About;